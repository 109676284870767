import { Button, Card, Col, Row, Tabs } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import StudentService from '../services/StudentService';
import PackageService from '../services/PackagesService';
import { formatDate } from '../utils';
import CourseService from '../services/CourseService';

const Students = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [packages, setPackages] = useState([]);
  const [totalCommisson, setTotalCommission] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [accountId, setAccountId] = useState<any>(0);

  useEffect(() => {
    getStudents()
    getPurchasedCourseList()
  }, []);

  const getPurchasedCourseList = () => {
    CourseService.getPurchasedCourseList()
      .then((response: any) => {
        const data = response.data;

        const totalCommission = data.reduce((total: any, item: any) => total + item.commission, 0);
        setTotalCommission(totalCommission);

        const totalPrice = data.reduce((total: any, item: any) => total + item.price, 0);
        setTotalSales(totalPrice);

        setPackages(data);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };


  const loginByAccountId = (id: any) => {
    setAccountId(id);
    setLoginLoading(true);
    StudentService.loginByAccountId(id)
      .then((response: any) => {
        const accessToken = response.data.tokenId;
        // Redirect using the token
        
        // Copy the token to clipboard
        window.open(`${process.env.REACT_APP_BASE_URL}/login-by-token/${accessToken}`, '_blank');
  
        setLoginLoading(false);
        setAccountId(null);
      })
      .catch((e: Error) => {
        setLoginLoading(false);
        console.error('Login failed', e);
      });
  }
  

  const getStudents = () => {
    setLoading(true);
    StudentService.getAll()
      .then((response: any) => {
        const data = response.data;
        setStudents(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const PackagesColumns: ColumnsType<any> = [
    {
      title: 'İsim',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Soyisim',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Paket',
      dataIndex: 'courseName',
      key: 'courseName',
    },
    {
      title: 'Paket Ücreti',
      dataIndex: 'price',
      render: (price) => <b>{price} ₺</b>,
    },
    {
      title: 'Komisyon',
      dataIndex: 'commission',
      render: (commission) => <b>{commission} ₺</b>,
    },
    {
      title: 'Komisyon Oranı',
      dataIndex: 'commissionRate',
      render: (commissionRate) => <b>%{commissionRate}</b>,
    },
    {
      title: 'Öğretmen',
      dataIndex: 'affiliate',
      key: 'affiliate',
      render: (affiliate) => <>{(affiliate != "null") ? affiliate : ""}</>,
    },
    {
      title: 'İşlem Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => <>{formatDate(createdAt)}</>,
      sorter: (a, b) => {
        // Parse the ISO 8601 date strings into timestamps for numerical sorting
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      },
      defaultSortOrder: 'descend', // Sets default sort order to descending
    },
  ];

  const StudentsColumns: ColumnsType<any> = [
    {
      title: 'İsim',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Soyisim',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'E-Posta',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => (
        <>
          {formatDate(createdAt)}
        </>
      ),
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: '',
      render: (id) => (
        <>
          <Row gutter={[24, 24]}>
            <Col>
              <NavLink to={`/students/${id}`}>
                <Button type="primary" block>
                  Detay
                </Button>
              </NavLink>

            </Col>
            <Col>
              <Button type="primary" loading={(loginLoading && accountId == id)} disabled={(loginLoading && accountId != id)} onClick={() => loginByAccountId(id)} block>
                Uygulama Giriş
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          label: 'Öğrenci Listesi',
          key: '1',
          children: <Card title="Öğrenci Listesi" extra={`Toplam Öğrenci: ${students.length}`}>
            <Table pagination={{ pageSize: 100 }} loading={loading} dataSource={students} columns={StudentsColumns} />
          </Card>,
        },
        {
          label: 'Paket Satış',
          key: '2',
          children: <Card title="Paket Satış" extra={<>Toplam Satış: <b>{packages.length}</b> |  Toplam Ciro: <b>{totalSales}</b> ₺ | Toplam Komisyon: <b>{totalCommisson}</b> ₺</>}>
            <Table pagination={{ pageSize: 100 }} loading={loading} dataSource={packages} columns={PackagesColumns} />
          </Card>
        }
      ]}
    />

  )
}

export default Students