import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Tooltip } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import CurriculumService from '../services/CurriculumService';
import SubjectTree from '../components/SubjectTree';
import CourseService from '../services/CourseService';
import ReactQuill from 'react-quill';
import { openNotification } from '../utils';

const { Item } = Form;

interface CurriculumOption {
  label: string;
  value: number;
}

interface Group {
  id: number;
  name: string;
  subjects: string[];
  checkedKeys: string[];
}

interface Grade {
  id: number;
  name: string;
  lessons: any[];
}

interface FormValues {
  name: string;
  description: string;
  subhead: string;
  listPrice: number;
  salePrice: number;
  thumbnail?: string;
  commissionRate: number;
  curriculumId: number;
  tags: string[];
  liveLessonCredit: number;
  aiChatCredit: number;
}

const CourseDetail: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [grades, setGrades] = useState<Grade[]>([]);
  const [curriculums, setCurriculums] = useState<CurriculumOption[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedCurriculum, setSelectedCurriculum] = useState<number | null>(null);

  const navigate = useNavigate();
  const { courseId } = useParams<{ courseId: string }>();

  const getAllCurriculums = () => {
    setLoading(true);
    CurriculumService.getAll()
      .then((response: any) => {
        const options = response.data.map((curriculum: { id: number; name: string }) => ({
          label: curriculum.name,
          value: curriculum.id,
        }));
        setCurriculums(options);
        setLoading(false);
      })
      .catch((e: Error) => {
        setLoading(false);
        console.error(e);
      });
  };

  const getGrades = (id: number) => {
    setGrades([]);
    setLoading(true);
    CurriculumService.getGradesByCurriculumId(id)
      .then((response: any) => {
        setGrades(response.data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.error(e);
        setLoading(false);
      });
  };

  const getCourse = () => {
    setLoading(true);
    CourseService.get(Number(courseId))
      .then((response: any) => {
        const data = response.data;
        form.setFieldsValue({
          name: data.name,
          description: data.description,
          subhead: data.subhead,
          listPrice: data.listPrice,
          salePrice: data.salePrice,
          commissionRate: data.commissionRate,
          curriculumId: data.curriculumId,
          tags: data.tags.split(','),
          thumbnail: data.coverImage,
          liveLessonCredit: data.liveLessonCredit,
          aiChatCredit: data.aiChatCredit,
        });
        setGroups(
          data.groups.map((group: any, index: number) => ({
            id: index + 1,
            name: group.name,
            subjects: group.subjects.map((subject: any) => subject.id.toString()),
            checkedKeys: group.subjects.map((subject: any) => `subject-${subject.id}`),
          }))
        );
        setSelectedCurriculum(data.curriculumId);
        getGrades(data.curriculumId);
        setLoading(false);
      })
      .catch((e: Error) => {
        setLoading(false);
        console.error(e);
      });
  };

  useEffect(() => {
    getAllCurriculums();
    if (courseId) {
      getCourse();
    }
  }, [courseId]);

  const handleCurriculumChange = (value: number) => {
    setSelectedCurriculum(value);
    getGrades(value);
  };

  const addGroup = () => {
    if (!selectedCurriculum) return;
    const newGroup: Group = {
      id: groups.length + 1,
      name: `Grup ${groups.length + 1}`,
      subjects: [],
      checkedKeys: [],
    };
    setGroups([...groups, newGroup]);
  };

  const removeGroup = (groupId: number) => {
    setGroups(groups.filter(group => group.id !== groupId));
  };

  const onGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>, groupId: number) => {
    const updatedGroups = groups.map(group => group.id === groupId ? { ...group, name: e.target.value } : group);
    setGroups(updatedGroups);
  };

  const onCheck = (checkedKeys: string[], groupId: number) => {
    const updatedGroups = groups.map(group => {
      if (group.id === groupId) {
        const subjectKeys = checkedKeys.filter(key => key.startsWith('subject-'));
        const subjectIds = subjectKeys.map(key => key.replace('subject-', ''));
        return { ...group, checkedKeys, subjects: subjectIds };
      }
      return group;
    });
    setGroups(updatedGroups);
  };

  const update = (values: FormValues) => {
    const groupedSubjects = groups.map(group => ({
      name: group.name,
      subjects: group.subjects,
    }));

    const postObj = {
      name: values.name,
      description: values.description,
      subhead: values.subhead,
      listPrice: values.listPrice,
      salePrice: values.salePrice,
      thumbnail: values.thumbnail,
      commissionRate: values.commissionRate,
      curriculumId: values.curriculumId,
      tags: values.tags.join(','),
      groups: groupedSubjects,
      liveLessonCredit: values.liveLessonCredit,
      aiChatCredit: values.aiChatCredit,
    };

    CourseService.update(Number(courseId), postObj)
      .then(() => {
        openNotification('success', 'Başarılı', 'Kurs Başarıyla Güncellendi.');
        navigate(-1);
      })
      .catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Kurs güncellenemedi.');
        console.error(e);
      });
  };

  return (
    <Card loading={loading} className='border-radius-md'>
      <Row align={'middle'} justify={'space-between'} className='mb-24'>
        <Col>
          <h3 className='fs-24 m-0'>Kurs Detay</h3>
        </Col>
        <Col>
          <Button onClick={() => navigate(-1)} className='fw-700 btn bg-cloud btn-hover-dark'>
            Geri
          </Button>
        </Col>
      </Row>
      <Form form={form} onFinish={update} layout="vertical">
        <Item
          name="name"
          label="Adı"
          rules={[
            {
              required: true,
              message: 'Ders adı zorunlu',
            },
          ]}
        >
          <Input />
        </Item>
        <Item name="subhead" label="Alt Başlık">
          <ReactQuill theme="snow" />
        </Item>
        <Item name="description" label="Açıklama">
          <ReactQuill theme="snow" />
        </Item>
        <Item name="tags" label="Etiket">
          <Select mode="tags" style={{ width: '100%' }} placeholder="Etiket" />
        </Item>
        <Item className='mb-0 pb-0' rules={[{ required: true, message: 'Fiyat bilgileri zorunlu' }]}>
          <Input.Group compact>
            <Item
              name="listPrice"
              label="Liste Fiyatı"
              rules={[{ required: true, message: 'Liste fiyatı zorunlu' }]}
              style={{ display: 'inline-block', width: 'calc(33.33% - 8px)' }}
            >
              <InputNumber className='w-full' placeholder="Liste Fiyatı" />
            </Item>
            <Item
              name="salePrice"
              label="Satış Fiyatı"
              rules={[{ required: true, message: 'Satış fiyatı zorunlu' }]}
              style={{ display: 'inline-block', width: 'calc(33.33% - 8px)', margin: '0 8px' }}
            >
              <InputNumber className='w-full' placeholder="Satış Fiyatı" />
            </Item>
            <Item
              name="commissionRate"
              label="Komisyon"
              rules={[{ required: true, message: 'Komisyon oranı zorunlu' }]}
              style={{ display: 'inline-block', width: 'calc(33.33% - 8px)' }}
            >
              <InputNumber className='w-full' placeholder="Komisyon" max={100} min={0} />
            </Item>
          </Input.Group>
        </Item>
        <Row gutter={16}>
          <Col span={8}>
            <Item
              name="curriculumId"
              label="Müfredat"
              rules={[
                {
                  required: true,
                  message: 'Müfredat bir ders seçin',
                },
              ]}
            >
              <Select options={curriculums} onChange={handleCurriculumChange} className='w-full' />
            </Item>
          </Col>
          <Col span={8}>
            <Item
              name="liveLessonCredit"
              label="Canlı Ders Sayısı"
              rules={[{ required: true, message: 'Canlı ders sayısı zorunlu' }]}
            >
              <InputNumber min={0} placeholder="Canlı Ders Sayısı" className='w-full' />
            </Item>
          </Col>
          <Col span={8}>
            <Item
              name="aiChatCredit"
              label="Yapay Zeka Chat Harf Kredisi"
              rules={[{ required: true, message: 'Yapay zeka chat karakter sayısı zorunlu' }]}
            >
              <InputNumber min={0} placeholder="Yapay Zeka Chat Harf Kredisi" className='w-full' />
            </Item>
          </Col>
        </Row>

        {/* Group Section */}
        <h3 className='fs-24'>Gruplar</h3>
        {groups.map(group => (
          <div key={group.id} style={{ marginBottom: '16px', padding: '8px', border: '1px solid #d9d9d9', borderRadius: '4px' }}>
            <Row gutter={8} align="middle">
              <Col span={20}>
                <Input
                  placeholder="Grup Adı"
                  value={group.name}
                  onChange={(e) => onGroupNameChange(e, group.id)}
                />
              </Col>
              <Col span={4}>
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => removeGroup(group.id)}
                />
              </Col>
            </Row>
            <SubjectTree
              grades={grades}
              checkedKeys={group.checkedKeys}
              onCheck={(checkedKeys) => onCheck(checkedKeys, group.id)}
            />
          </div>
        ))}
        <Tooltip title={selectedCurriculum ? '' : 'Grup eklemek için bir müfredat seçin'}>
          <Button
            type="dashed"
            onClick={addGroup}
            icon={<PlusOutlined />}
            className='mt-16'
            disabled={!selectedCurriculum}
          >
            Grup Ekle
          </Button>
        </Tooltip>

        <Row gutter={8} justify={"end"} className='mt-16'>
          <Col>
            <Item>
              <Button htmlType="submit" className='w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'>
                Kaydet
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CourseDetail;
